import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot,CanActivateChild } from '@angular/router';
declare var $:any;
@Injectable()
export class Auth implements CanActivate,CanActivateChild {
    constructor(private router: Router) { }
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        var url = state.url;
        if (localStorage.getItem('client')) {
            return true;
        }
        else {
            $('#mustLogin').modal('show');
          //  this.router.navigate(['user']);
        }
    }

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        var url = state.url;
        if (localStorage.getItem('client')) {
            return true;
        }
        else {
            $('#mustLogin').modal('show');
           // this.router.navigate(['user']);
        }
        
    }
    
}
