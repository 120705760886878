import { Injectable } from '@angular/core';
import {Observable,Subject,BehaviorSubject} from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class MessageService {


  private subject = new BehaviorSubject<any>({});
  private custom_subject = new BehaviorSubject<any>({});

  constructor() { }


  send_message(message){
    this.subject.next({text:message});
  }
  send_custom_message(data){
    this.custom_subject.next(data);
  }

  get_message(){

    return this.subject.asObservable();
  }
  get_custom_message(){

    return this.custom_subject.asObservable();
  }

}
