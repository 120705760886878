import { Component, OnInit, HostListener } from '@angular/core';
import { MessageService } from './../../services/message.service';
import { Router, ActivatedRoute } from '@angular/router';

import {Location} from '@angular/common';

declare var $: any;
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  show_back: boolean = true;
  constructor(
    private message: MessageService,
    private router: Router,
    private _location: Location
  ) { }


  search_value: any = "";

  ngOnInit() {
    if(localStorage.getItem('search_value')){
      this.search_value= localStorage.getItem('search_value');
    }
    this.get_message();
  }

  get_message() {
    this.message.get_custom_message().subscribe(data => {
      if (data['header']) {
        if (data.header == "hide_back") {
          this.show_back = false;
        }
        if (data.header == "show_back") {
          this.show_back = true;
        }
      }
    })
  }

  open_side_menu() {
    this.message.send_message('open_side_menu');
  }

  search() {
    localStorage.setItem('search_value',this.search_value);
    this.router.navigate(['/dashboard/search/' + this.search_value + '/simple']);
  }

  @HostListener('window:scroll', ['$event'])
  onScroll() {

    if ($(window).scrollTop() > 50) {
     // $('.header').addClass('sticky');
    }
    else {
      $('.header').removeClass('sticky');
    }

  }

  back() {
    var url = this.router.url.split('/')[1]
    if (url == "dashboard" || url == "search") {
      this.router.navigate(['dashboard']);
    }
    else {
      this._location.back();
    }
  }

}
