import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'campaignPage'
})
export class CampaignPagePipe implements PipeTransform {

  
  transform(item: any,campaign): any {
    var amount=0;
    var type=campaign.DiscountType;
    var value= item.NormalPrice;
    var discount= campaign.TotalDiscount;
    if(type==1){
         amount = value -  ((value * discount)/100);
    }
    else if (type==2) {
      amount= value - discount;

    }

    else{
      amount= item.DiscountedPrice;
    }
    return amount;
  }

}
