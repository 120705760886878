import { Injectable } from "@angular/core";
import { HttpClient, HttpParams, HttpHeaders } from "@angular/common/http";
import { map, tap, catchError, takeUntil } from "rxjs/operators";
import { throwError } from "rxjs";
import { ToastrService } from "ngx-toastr";
import { Router, ActivatedRoute } from "@angular/router";
import { TakeuntilService } from "./takeuntil.service";
import { CommonService } from "./common.service";
import { MessageService } from "./message.service";
import { TranslateService } from "./translate.service";

declare var $: any;
@Injectable({
  providedIn: "root",
})
export class HttpServiceService {
  constructor(
    private http: HttpClient,
    private toastr: ToastrService,
    private router: Router,
    private active_route: ActivatedRoute,
    private take_until: TakeuntilService,
    private common: CommonService,
    private message: MessageService,
    private translate: TranslateService
  ) {}

  get_config(params) {
    var headers = new HttpHeaders({
      "Content-Type": "application/json;charset:utf-8",
    });
    var config = { headers: headers, params: params };
    return config;
  }

  post_config() {
    var headers = new HttpHeaders({
      "Content-Type": "application/x-www-form-urlencoded",

      // 'Content-Type': 'application/json;charset:utf-8',
    });

    var defaultToken =
      "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjbGllbnRJRCI6MjMsImNoZWNrVmFsdWUiOiJCdXQlIUwyMDE5IiwiaWF0IjoxNTc5NTExNTcxfQ.IObKXvKOWPm2zRycpB65mLbU9_L7eWm0S4Uqj-ZLOJQ";

    var userToken = localStorage.getItem("token");
    //console.log('user token:' + userToken);

    if (userToken) {
      headers = headers.set("Authorization", `Bearer ${userToken}`);
      //console.log("header set : " + userToken);
    } else {
      headers = headers.set("Authorization", `Bearer ${defaultToken}`);
    }

    var config = { headers: headers };
    return config;
  }

  http_get(url, params) {
    var http_params = new HttpParams().set("data", JSON.stringify(params));

    return this.http
      .get<any>(
        this.common.get_url().base_api + url,
        this.get_config(http_params)
      )
      .pipe(
        tap((data) => {
          return data;
        }),
        catchError((error) => {
          return throwError(error.error);
        })
      );
  }

  http_post(params) {
    var http_params = new HttpParams().set("data", JSON.stringify(params));
    var header_config = this.post_config();
    return this.http
      .post<any>(this.common.get_url().base_api, http_params, header_config)
      .pipe(
        tap((data) => {
          if (data.hasError == true) {
            this.navigate();
            //  this.toastr.error("مشکلی پیش آمده است");
          }
          return data;
        }),
        catchError((error) => {
          this.toastr.clear();
          this.toastr.error(this.translate.data.connection_error);
          this.message.send_message("hide_loader");
          return throwError(error.error);
        }),
        takeUntil(this.take_until.$unsubscribe)
      );
  }

  navigate() {
    var current = this.router.url;

    var route = this.last_split(current);
    if (
      route != "login" &&
      route != "confirm" &&
      route != "changemobile" &&
      route != "changemobileconfirm"
    ) {
      $("#mustLogin").modal("show");
      //this.router.navigate(['user']);  //it must uncomment later
    } else {
    }
  }

  last_split(param) {
    var split_param = String(param).split("/");
    return split_param[split_param.length - 1];
  }
}
