import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {map,catchError,tap} from 'rxjs/operators';
import {throwError} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TranslateService {


  data:any={};
  constructor(private http:HttpClient) { }


/*   use(lang:string):any{
    const  langPath="./assets/i18n/"+lang+".json";
   return this.http.get<any>(langPath).subscribe(
    data=>{
      this.data=data;
    }
  )
} */


use(lang: string): Promise<{}> {
  return new Promise<{}>((resolve, reject) => {
    const langPath = `./assets/i18n/${lang || 'en'}.json`;
    this.http.get<{}>(langPath).subscribe(
      translation => {
        this.data = Object.assign({}, translation || {});
        resolve(this.data);
      },
      error => {
        this.data = {};
        resolve(this.data);
      }
    );
  });
}

}
