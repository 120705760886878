import { Component, OnInit, HostListener } from "@angular/core";
import { HttpServiceService } from "./../../../share/services/http-service.service";
import { MessageService } from "./../../../share/services/message.service";
import { CommonService } from "./../../../share/services/common.service";
import { TranslateService } from "./../../../share/services/translate.service";
import {
  trigger,
  state,
  style,
  transition,
  animate,
} from "@angular/animations";
import { GetClientInfo } from "../../models/request/get-client-info";
import { ServiceApiEnum } from "../../enums/service-api-enum.enum";
import { ClientInfo } from "./../../../share/models/response/client-info";
import { User } from "../../models/db_models/user";
import { Router } from "@angular/router";

@Component({
  selector: "app-side-menu",
  templateUrl: "./side-menu.component.html",
  styleUrls: ["./side-menu.component.scss"],
  animations: [
    trigger("slide_right_toggle", [
      state(
        "close",
        style({
          right: "-280px",
        })
      ),
      state(
        "open",
        style({
          right: 0,
        })
      ),
      transition("open <=> close", animate("300ms linear")),
    ]),
  ],
})
export class SideMenuComponent implements OnInit {
  percent: any = 280;
  side_display: any = "none";
  side_right: any = "translateX(270px)";
  side_left: any = -280;
  size_500: any;
  size_990: any;
  dir: any = "rtl";
  side_status: any;
  user_info: ClientInfo = new ClientInfo();
  base_url: any;
  current_time: any;
  state: any;
  parent_left: any = "-100%";
  hide_back_drop = true;
  constructor(
    private message: MessageService,
    private http: HttpServiceService,
    private common: CommonService,
    private router: Router
  ) {}

  ngOnInit() {
    this.user_info.data = <any>{};
    this.close_side_menu();
    this.get_message();
    this.base_url = this.common.get_url();
    var date = new Date();
    this.current_time = date.getTime();
  }

  get_message() {
    this.message.get_message().subscribe((data) => {
      if (data.text == "open_side_menu") {
        // this.open_side();
        this.open_side_menu();
      }
      if (data.text == "close_side_menu") {
        // this.open_side();
        this.close_side_menu();
      }
      if (data.text == "get_user_info") {
        this.get_info();
      }
    });
  }
  /*  @HostListener('window:resize', ['$event'])
   onResize(event) {
     this.check_500();
   }
 */

  open_side_menu() {
    this.hide_back_drop = false;
    if (this.dir == "rtl") {
      this.side_right = "translateX(0px)";
      this.side_left = "none";
    } else {
      this.side_left = 0;
      this.side_right = "none";
    }
  }
  close_side_menu() {
    this.hide_back_drop = true;
    if (this.dir == "rtl") {
      this.side_right = "translateX(270px)";
      this.side_left = "none";
    } else {
      this.side_left = -1 * this.percent + "px";
      this.side_right = "none";
    }
  }
  side_click(event) {
    if (
      document
        .getElementsByClassName("side-content")[0]
        .contains(event.target) == false
    ) {
      this.close_side_menu();
    }
  }

  /* @HostListener('window:click',['$event'])
  click(event){
    if (document.getElementsByClassName('side-content')[0].contains(event.target) == false) {
      this.close_side_menu();
    }
  } */

  get_info() {
    var params = <GetClientInfo>{};
    params.p1 = Number(localStorage.getItem("client"));
    params.sn = ServiceApiEnum.GetClientInfo;
    this.http.http_post(params).subscribe((data: ClientInfo) => {
      this.user_info = data;
    });
  }

  get_profile() {
    this.router.navigate(["user/profile"]);
  }

  signout() {
    localStorage.removeItem("client");
    localStorage.removeItem("token");
    this.router.navigate(["user/login"]);
  }

  error_image(event) {
    event.target.setAttribute("src", "/assets/img/profilepicture_avatar.jpg");
  }
}
