import { Injectable } from '@angular/core';
import {Subject} from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class TakeuntilService {

  public $unsubscribe= new Subject<{}>();
  constructor() { }

  unsubscribe(){
   this.$unsubscribe.next();
    //this.$unsubscribe.complete();
  }

}
