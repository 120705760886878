import { Pipe, PipeTransform } from '@angular/core';
import {CommonService} from './../services/common.service';
@Pipe({
  name: 'weekDays'
})
export class WeekDaysPipe implements PipeTransform {

  constructor(private common:CommonService){}
  transform(value: any): any {
    return this.common.get_day(value-1);
  }

}
