import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'campaign'
})
export class CampaignPipe implements PipeTransform {

  transform(item: any): any {
    var amount=0;
    var type=item.Campaign.DiscountType;
    var value= item.NormalPrice;
    var discount= item.Campaign.TotalDiscount;
    if(type==1){
         amount = value-(value * (100-discount))/100;
    }
    else if (type==2) {
      amount= value- discount;

    }

    else{
      amount= item.DiscountedPrice;
    }
    return amount;
  }

}
