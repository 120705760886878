import { Pipe, PipeTransform } from '@angular/core';
import { ClientInfo } from '../models/response/client-info';
import {TranslateService} from './../../share/services/translate.service';

@Pipe({
  name: 'userBalance'
})
export class UserBalancePipe implements PipeTransform {
  constructor(
    private translate : TranslateService
  ){

  }

  transform(user: ClientInfo): any {
      if(user.data.WalletBalance > 0){
        return this.translate.data.creditor;
      }
      else{
        return this.translate.data.debtor
      }
  }

}
