import { Component } from '@angular/core';
import { TranslateService } from './share/services/translate.service';
import { Router, ActivatedRoute, NavigationEnd ,NavigationStart,RoutesRecognized } from '@angular/router';
import { CommonService } from './share/services/common.service';
import {MessageService} from './share/services/message.service';
import {TakeuntilService} from './share/services/takeuntil.service';
import { UpdateService } from './update.service';
declare var $:any;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'ButyleClient';
  master_background: any;

  constructor(
    private translate: TranslateService,
    private router: Router,
    private common: CommonService,
    private message:MessageService,
    private take_until:TakeuntilService,
   private update:UpdateService
  ) {
   this.update.checkForUpdates();
   }

  ngOnInit() {


    this.common.get_user_position();

    this.onChangePage();



  }


  onChangePage() {

    this.router.events.subscribe((event:any) => {

      

      
      if (event instanceof NavigationStart) {

         this.take_until.unsubscribe();

        var first= event.url.split('/')[2];
        if(first=="home"||first==undefined){

          var data=<any>{};
          data.header="hide_back";
          this.message.send_custom_message(data);
          console.log(data)
        }
        else {
          var data=<any>{};
          data.header="show_back";
          this.message.send_custom_message(data);
        }


        localStorage.setItem('previous_url',this.router.url);
        

      }

      if (event instanceof NavigationEnd) {
        
        $('.modal').modal('hide');
        $("#share").jsSocials("destroy");
        this.message.send_message('close_side_menu')
        $(".swiper-container").each(function () {
          if (this.swiper) {
            this.swiper.destroy();
          }
        });
        var url = this.get_url(event);
        if (url == "home") {
          // this.master_background="url('./../assets/img/background.jpeg')";
        }

      
      }




    })

  }

  get_url(data) {
    var split_url = String(data.url).split('/');
    var count = split_url.length;
    return split_url[count - 1];

  }

  login(){
    this.router.navigate(['/user/login'])
  }





}
