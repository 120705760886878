import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'credit'
})
export class CreditPipe implements PipeTransform {

  transform(item: any): any {
    if (item.Credit != 0) {
      var data = <any>{};
      data.amount =item.Credit;
      data.icon= "fa-plus";
      return data;
    }
    else if (item.Debit != 0) {
      var data = <any>{};
      data.amount =item.Debit;
      data.icon= "fa-minus";
      return data;
    }
  }

}
