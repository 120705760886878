import { Component, OnInit,Input, HostListener  } from '@angular/core';
import { HttpServiceService } from './../../services/http-service.service';
import { CommonService } from './../../services/common.service';
import { MessageService } from './../../services/message.service';
import { ServiceApiEnum } from './../../enums/service-api-enum.enum';
import { GetProviderReviews } from './../../models/request/get-provider-reviews';
import { Review } from './../../models/response/review';
import { ProviderInfo } from './../../models/response/provider-info';
import { OfficeInfo } from './../../models/response/office-info';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { SaveProviderReview } from './../../models/request/save-provider-review';
import { Base } from './../../models/response/base';
import { TranslateService } from './../../services/translate.service';
declare var $:any;
@Component({
  selector: 'app-add-comment',
  templateUrl: './add-comment.component.html',
  styleUrls: ['./add-comment.component.scss']
})
export class AddCommentComponent implements OnInit {

  @Input() info: any = <any>{};
  @Input() type: any;
  page: any = 0;
  size: any = 10;
  comments: Review = <Review>{};
  user_rate: any = 0;
  formdata: any;
  submited: any = false;
  comment: any;
  comment_loader: any = false;
  description: any;
  constructor(
    private http: HttpServiceService,
    private common: CommonService,
    private message: MessageService,
    private toastr: ToastrService,
    private translate: TranslateService
  ) { }





  ngOnInit() {






    this.get_message();

  }


  get_message() {

    this.message.get_message().subscribe(data => {
     
    })

  }



  
  get_current_feature_save() {
    var id = 0;
    var sn = 0;
    if (this.type == "provider") {
      id = this.info.data.ProviderID;
      sn = ServiceApiEnum.SaveProviderReview;
    }
    if (this.type == "office") {
      id = this.info.data.OfficeID;
      sn = ServiceApiEnum.SaveOfficeReview;

    }
    if (this.type == "service") {
      id = this.info.data.ServiceID;
      sn = ServiceApiEnum.SaveServiceReview;
    }

    return [id, sn];

  }


  set_star(rate) {

    this.black_star();
    this.gold_star(rate);
    this.user_rate = rate;
  


  }

  gold_star(rate) {
    var star = document.getElementsByClassName('star');
    this.black_star();
    for (var i = 0; i < rate; i++) {
      star[i].setAttribute('class', 'fa fa-star star gold');
    }
  }

  black_star() {
    var star = document.getElementsByClassName('star');
    var count = star.length;
    for (var i = 0; i < count; i++) {
      star[i].setAttribute('class', 'fa fa-star-o star black');
    }
  }

  open_modal() {
   //   this.formdata.reset();
   this.description = "";
    this.submited = false;
    this.black_star();
    this.user_rate = 0;
  }


  add_comment() {
    this.toastr.clear();
    this.submited = true;
    if (this.user_rate != 0) {
        this.comment_loader = true;
        var params = <SaveProviderReview>{};
        params.p1 = Number(localStorage.getItem('client'));
        params.p2 = this.get_current_feature_save()[0];
        params.p3 = this.user_rate * 2;
        params.p4 = this.description;
        params.sn = this.get_current_feature_save()[1];
        this.http.http_post(params).subscribe((data: Base) => {
          this.comment_loader = false;
          if (data.hasError == false) {
            $('#comment-modal').modal('hide');
            this.toastr.success(this.translate.data['comment_success_message']);
          }
        })



    }
    else {
      this.toastr.error(this.translate.data['comment_error_message'], '', {
        timeOut: 3000
      })
    }

  }


}
