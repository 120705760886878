import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'convertToPixel'
})
export class ConvertToPixelPipe implements PipeTransform {

  transform(value: any,item: any): any {
    console.log(item)
    return document.getElementById('swiper_'+item+'').clientWidth;
  }

}
