import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'star'
})
export class StarPipe implements PipeTransform {

  transform(count: any): any {
    var stars = "";
    if (count) {
      var final_count = Math.floor(count / 2);
      for (var i = 0; i < final_count; i++) {
        stars += '<i class="fa fa-star"></i>'
      }
      var empty_count = 5 - final_count;
      for (var i = 0; i < empty_count; i++) {
        stars += '<i class="fa fa-star-o"></i>'
      }
    }
    else {
      for (var i = 0; i < 5; i++) {
        stars += '<i class="fa fa-star-o"></i>'
      }
    }


    return stars;


  }

}
