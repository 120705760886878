export enum ServiceApiEnum {

    SendLoginVerifyCode = 1,
    LoginByVerifyCode = 2,
    GetFeatures = 3,
    GetFeatureSlides = 4,
    GetCampaignServices = 5,
    GetClientSearch = 6,
    GetAdvanceSearchResults = 7,
    ClientAdvanceSearch = 8,
    ClientQuickSearch = 9,
    GetServiceCategories = 10,
    GetProviderInfo = 11,
    GetProviderCalendars = 12,
    GetProviderServices = 13,
    GetProviderReviews = 14,
    AddClientFavoriteProvider = 15,
    RemoveClientFavoriteProvider = 16,
    SaveProviderReview = 17,
    GetOfficeInfo = 18,
    GetOfficeProviders = 19,
    GetOfficeReviews = 20,
    AddClientFavoriteOffice = 21,
    RemoveClientFavoriteOffice = 22,
    SaveOfficeReview = 23,
    CheckPromoCode = 24,
    CreateBook = 25,
    GetClientBooks = 26,
    GetClientBookCancelReasons = 27,
    ClientBookCancel = 28,
    GetOfficesByLocation = 29,
    GetClientInfo = 30,
    UpdateClientInfo = 31,
    SendChangeMobileNumberVerifyCode = 32,
    GetClientWalletTransactions = 33,
    GetClientFavoriteOffices = 34,
    GetClientFavoriteProviders = 35,
    GetClientFavoriteServices = 36,
    AddClientFavoriteService = 37,
    GetClientMessages = 38,
    GetClientMessageDetail = 39,
    ChangeClientMobileNumber = 40,
    GetClient = 41,
    DeleteClientMessage = 42,
    RemoveClientFavoriteService = 43,
    GetServiceByServiceID = 44,
    GetServiceReviews = 45,
    LoginByClientID = 46,
    UpdateClientProfilePicture = 47,
    SaveServiceReview = 48,
    GetFeatureServices = 49,
    GetFeatureOffices = 50,
    GetFeatureProviders = 51,
    GetCampaignByCampianID = 52,
    GetBookByBookID = 53,
    GetProviderCalendarEventsByDate = 54,
    CheckPromoCodeByOwner  = 58


}
