import { Base } from "../response/base";
export class User extends Base {
  ClientID: number;
  FirstName: string;
  LastName: string;
  MobileNumber: string;
  Email: string;
  WalletBalance: number;
  UniqueCode: string;
  Grade: number;
  ProfilePicturePath: string;
  hasError: any;
  Token: string;
}
