import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from './../../services/message.service';
@Component({
  selector: 'app-back-header',
  templateUrl: './back-header.component.html',
  styleUrls: ['./back-header.component.scss']
})
export class BackHeaderComponent implements OnInit {

  @Input() title: string = "";
  constructor(
    private active_route: ActivatedRoute,
    private router: Router,
    private message: MessageService
  ) { }

  ngOnInit() {
  }


  back() {
    var current = this.active_route.routeConfig.path;
    if (String(current).split('/').length > 0) {
      current = String(current).split('/')[0];
    }
    if (current == "upload") {
      this.router.navigate(['user/profile'])
    }
    else if (current == "userinfo") {
      this.router.navigate(['user/profile'])
    }

    else if (current.indexOf('basket') > -1) {
      if (localStorage.getItem('provider_info')) {
        var provider_info = JSON.parse(localStorage.getItem('provider_info'));
        this.router.navigate(['dashboard/provider/', provider_info.ProviderID]);
      }
      else {
        window.history.go(-1)
      }
    }

    /*  else if (current == "selectdate") {
       var data=<any>{};
       data.page="selectdate";
       data.message="get_basket";
       this.message.send_custom_message(data);
     }
     else if (current == "basket") {
       var data=<any>{};
       data.page="basket";
       data.message="get_provider";
       this.message.send_custom_message(data);
     //  this.router.navigate(['book'])
     } */
    else {
      //this.router.navigate(['dashboard'])
      window.history.go(-1)
      // window.history.back();
    }


  }

  get_dashboard() {

    this.router.navigate(['dashboard']);

  }

}
