import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {DashboardModule} from './dashboard/dashboard.module';
import {UserModule} from './user/user.module';
//import {BookModule} from './book/book.module';
const routes: Routes = [
  {path:'dashboard',loadChildren : () => import('./dashboard/dashboard.module').then(m => m.DashboardModule), },
  {path:'',redirectTo:'dashboard/home',pathMatch:'full'},
  {path:'user',loadChildren : () => import('./user/user.module').then(m => m.UserModule), },
  {path:'book',loadChildren : () => import('./book/book.module').then(m => m.BookModule), },
  {path:'feature',loadChildren : () => import('./features/features.module').then(m => m.FeaturesModule), },

 // {path:'book',loadChildren :'./book/book.module#BookModule' },


];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
