import { Component, OnInit, Input, HostListener } from '@angular/core';
import { HttpServiceService } from './../../services/http-service.service';
import { CommonService } from './../../services/common.service';
import { MessageService } from './../../services/message.service';
import { ServiceApiEnum } from './../../enums/service-api-enum.enum';
import { GetProviderReviews } from './../../models/request/get-provider-reviews';
import { Review } from './../../models/response/review';
import { ProviderInfo } from './../../models/response/provider-info';
import { OfficeInfo } from './../../models/response/office-info';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { SaveProviderReview } from './../../models/request/save-provider-review';
import { Base } from './../../models/response/base';
import { TranslateService } from './../../services/translate.service';

declare var $: any;
@Component({
  selector: 'app-comment',
  templateUrl: './comment.component.html',
  styleUrls: ['./comment.component.scss']
})
export class CommentComponent implements OnInit {

  @Input() info: any = <any>{};
  @Input() type: any;
  page: any = 0;
  size: any = 10;
  comments: Review = <Review>{};
  base_url: any;
  user_rate: any = 0;
  formdata: any;
  submited: any = false;
  comment: any;
  comment_loader: any = false;
  scrollEnd: boolean = false;
  allow_scroll_infinity: boolean = true;
  empty: any = false;
  show_comments: boolean = true;
  allow_infinity: boolean = true;
  constructor(
    private http: HttpServiceService,
    private common: CommonService,
    private message: MessageService,
    private toastr: ToastrService,
    private translate: TranslateService
  ) { }





  ngOnInit() {

    this.comments.data = [];

    this.get_comments();

    this.base_url = this.common.get_url();

    this.validator();


    this.get_message();

  }


  get_message() {
    this.message.get_message().subscribe(data => {
      if (data.text == "show_comments") {
        this.show_comments = true;
      }
      if (data.text == "hide_comments") {
        this.show_comments = true;
      }

    })

  }


  validator() {
    this.formdata = new FormGroup({
      message: new FormControl('', Validators.compose([
        Validators.required,
        Validators.minLength(3),
      ]))
    })

  }

  get_comments() {

    if (this.page > 0) {
      this.scrollEnd = true;
    }
    var params = <any>{};
    params.p1 = this.get_current_feature_get()[0];
    params.sn = this.get_current_feature_get()[1];
    params.p2 = this.page * this.size;
    params.p3 = this.size;

    this.http.http_post(params).subscribe((data: Review) => {
      this.scrollEnd = false;
      var count = data.data.length;

      for (var i = 0; i < count; i++) {
        this.comments.data.push(data.data[i]);
        this.message.send_message('');
      }
      if (data.data.length > 0) {
        this.page++;
        this.empty = false;
        setTimeout(() => {
           this.allow_infinity=true;
        }, 100);
      }
      else {
     
        this.allow_infinity=false;

        this.empty = true;
      }

      setTimeout(() => {
        if (this.page > 1) {
          $(window).scrollTop($(window).scrollTop() + 40);
        }
      }, 1);

    })

  }
  check_display() {
    var current_tab = $('.tab-item.2').attr('class');
    if (String(current_tab).indexOf("swiper-slide-active") > -1 || String(current_tab).indexOf("active") > -1) {
      if (this.empty == false) {
        this.allow_scroll_infinity = true;
      }
    }
    else {
      this.allow_scroll_infinity = false;
    }

  }

  get_current_feature_get() {
    var id = 0;
    var sn = 0;
    if (this.type == "provider") {
      id = this.info.data.ProviderID;
      sn = ServiceApiEnum.GetProviderReviews;
    }
    if (this.type == "office") {
      id = this.info.data.OfficeID;
      sn = ServiceApiEnum.GetOfficeReviews;
    }
    if (this.type == "service") {
      id = this.info.data.ServiceID;
      sn = ServiceApiEnum.GetServiceReviews;
    }

    return [id, sn];

  }
  get_current_feature_save() {
    var id = 0;
    var sn = 0;
    if (this.type == "provider") {
      id = this.info.data.ProviderID;
      sn = ServiceApiEnum.SaveProviderReview;
    }
    if (this.type == "office") {
      id = this.info.data.OfficeID;
      sn = ServiceApiEnum.SaveOfficeReview;

    }
    if (this.type == "service") {
      id = this.info.data.ServiceID;
      sn = ServiceApiEnum.SaveServiceReview;
    }

    return [id, sn];

  }

  error_image(event) {
    event.target.setAttribute('src', '/assets/img/profilepicture_avatar.jpg');
  }

  onScrolls() {
    this.check_display();
    if (this.allow_scroll_infinity == true) {
      this.get_comments();
    }
  }

  @HostListener('window:scroll', ['$event'])
  onScroll() {
    if (this.common.scroll_end() == true) {
       this.check_display();
      if (this.allow_scroll_infinity == true&&this.allow_infinity==true) {
        this.allow_infinity=false;
        this.get_comments();
      }
    }
  }



set_star(rate) {
  this.black_star();
  this.gold_star(rate);
  this.user_rate = rate;
}

gold_star(rate) {
  var star = document.getElementsByClassName('star');
  this.black_star();
  for (var i = 0; i < rate; i++) {
    star[i].setAttribute('class', 'fa fa-star star gold');
  }
}

black_star() {
  var star = document.getElementsByClassName('star');
  var count = star.length;
  for (var i = 0; i < count; i++) {
    star[i].setAttribute('class', 'fa fa-star-o star black');
  }
}

open_modal() {
  this.formdata.reset();
  this.submited = false;
  this.black_star();
  this.user_rate = 0;
}


add_comment(value) {
  this.toastr.clear();
  this.submited = true;
  if (this.user_rate != 0) {
    if (this.formdata.valid) {
      this.comment_loader = true;
      var params = <SaveProviderReview>{};
      params.p1 = Number(localStorage.getItem('client'));
      params.p2 = this.get_current_feature_save()[0];
      params.p3 = this.user_rate;
      params.p4 = value.message;
      params.sn = this.get_current_feature_save()[1];
      this.http.http_post(params).subscribe((data: Base) => {
        this.comment_loader = false;
        if (data.hasError == false) {
          $('#comment-modal').modal('hide');
          this.toastr.success(this.translate.data['comment_success_message']);
        }
      })

    }

  }
  else {
    this.toastr.error(this.translate.data['comment_error_message'], '', {
      timeOut: 3000
    })
  }

}






}
