import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'translateApi'
})
export class TranslateApiPipe implements PipeTransform {

  transform(value: any, item): any {
    if (item[value]) {
      var json_value = JSON.parse(item[value]);
      if (localStorage.getItem('language')) {
        var lang = localStorage.getItem('language');
        if (lang == "fa") {
          return json_value.fa;
        }
        else {
          return json_value.en;
        }
      }
      else {
        return json_value.fa;
      }
    }
    else {

    }

  }

}
