import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'jalali-moment';

@Pipe({
  name: 'dateConvert'
})
export class DateConvertPipe implements PipeTransform {

  transform(value: any,type:any): any {
    var date = new Date(value);
    let MomentDate = moment(date);
    var lan="en";
    if(localStorage.getItem("language")=="fa"){
          lan="fa";
    }
   
    return MomentDate.locale(lan).format(type);
  }

}
