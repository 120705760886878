import { Component, OnInit } from '@angular/core';
import { MessageService } from './../../services/message.service';
@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {

  constructor(
    private message: MessageService
  ) { }

  show_loader: any;
  ngOnInit() {

    this.message.get_message().subscribe(message => {
      if (message.text == "show_loader") {
        this.show_loader = true;
      }
      if (message.text == "hide_loader") {
        this.show_loader = false;
      }
    })

  }

}
