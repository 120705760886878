import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'messageStatus'
})
export class MessageStatusPipe implements PipeTransform {

  transform(item: any): any {

    if(item.IsRead==false){
      return "not-seen";
    }
    else{
      return "seen";
    }
    

  }

}
