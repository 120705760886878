import { Injectable } from '@angular/core';
import { CampaignPipe } from './../pipes/campaign.pipe';
import { TranslateService } from './../services/translate.service';

declare var $: any;
@Injectable({
  providedIn: 'root'
})
export class CommonService {

  constructor(
    private translate: TranslateService
  ) { }

  get_url() {
    return this.live_url();
    // return this.test_url();
  }
  live_url() {
    var data = <any>{};
    data.base_img = "https://appsrv1.beautyles.com:8052/client/image/";
    data.base_api = "https://appsrv1.beautyles.com:8052/client/api/"
    return data;
  }
  test_url() {
    var data = <any>{};
    data.base_img = "https://devapp.beautyles.com:8052/client/image/";
    data.base_api = "https://devapp.beautyles.com:8052/client/api/"
    return data;
  }


  get_user_position() {

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((data) => { this.current_position(data) }, (err) => { this.error_location(err) }, { enableHighAccuracy: true });
    }
    else {
      var data = <any>{};
      data.latitude = 0;
      data.longitude = 0;
      localStorage.setItem('user_position', JSON.stringify(data));
      this.get_location();
    }

  }
  error_location(err) {
    var data = <any>{};
    data.latitude = 0;
    data.longitude = 0;
    localStorage.setItem('user_position', JSON.stringify(data));
    this.get_location();
    console.log(err);
  }

  current_position(position) {
    var data = <any>{};
    data.latitude = position.coords.latitude;
    data.longitude = position.coords.longitude;
    localStorage.setItem('user_position', JSON.stringify(data));
    return data;

  }

  get_location() {

    var location = <any>{};
    var final_location = <any>{};
    if (localStorage.getItem('user_position')) {
      location = JSON.parse(localStorage.getItem('user_position'));
      final_location.Lat = location.latitude;
      final_location.Lng = location.longitude;
    }
    else {
      final_location.Lat = 0;
      final_location.Lng = 0;
    }

    return final_location;
  }

  get_day(index) {
    var days = ["شنبه", "یکشنبه", "دوشنبه", "سه شنبه", "چهارشنبه ", "پنج شنبه", "جمعه"];
    return days[index];
  }

  find_like(id) {


      if (localStorage.getItem('likes')) {

        var index = -1;
        var likes = JSON.parse(localStorage.getItem('likes'));
        var count = likes.length;

        for (var i = 0; i < count; i++) {
          if (likes[i].id == id) {
            index = i;
            break;
          }
        }

        return index;

      }
      else {
        return -1;
      }

  
  }

  set_like(id, type) {
    var index = this.find_like(id);
    if (index == -1) {
      var likes = [];
      if (localStorage.getItem('likes')) {
        likes = JSON.parse(localStorage.getItem('likes'));
      }
      var data = <any>{};
      data.type = "service";
      data.id = id;
      likes.push(data);
      localStorage.setItem('likes', JSON.stringify(likes));

    }
  }

  remove_like(id) {

    var index = this.find_like(id);
    if (index > -1) {
      var likes = JSON.parse(localStorage.getItem('likes'));
      likes.splice(index, 1);
      localStorage.setItem('likes', JSON.stringify(likes));
    }



  }


  is_scroll_end() {
    var _windowHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight,
      _scrollPos = window.scrollY || window.pageYOffset || document.documentElement.scrollTop;

    if (($(window).scrollTop() + $(window).height()) >= $(document).height() - 10) {
      //  if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight) {

      return true;
    }
    else {
      return false;
    }
  }


  get_selected_services_time() {

    if (localStorage.getItem('card')) {
      var sum = 0;

      var card = JSON.parse(localStorage.getItem('card'));
      var card_count = card.length;
      for (var i = 0; i < card_count; i++) {
        sum += card[i].x.DurationMinute;
      }

      return sum;

    }
    else {
      return 0;
    }

  }

  calculate_price() {

    if (localStorage.getItem('card')) {

      var price = 0;
      var card = JSON.parse(localStorage.getItem('card'));
      var card_count = card.length;
      for (var i = 0; i < card_count; i++) {
        if (card[i].x["Campaign"]) {
          var campaign = new CampaignPipe();
          price += campaign.transform(card[i].x);
        }
        else {
          if (this.time_in_discount() == true) {
            price += card[i].x.DiscountedPrice;
          }
          else {
            price += card[i].x.NormalPrice;
          }
        }
      }

      return price;

    }
    else {
    }

  }

  time_in_discount() {
    if (localStorage.getItem('selected_time')) {
      var time = JSON.parse(localStorage.getItem('selected_time'));
      if (time.data.IsInDiscount == true) {
        return true;
      }
      return false;
    }
  }

  service_in_campaign() {

    if (localStorage.getItem('card')) {

      var index = 0;

      var card = JSON.parse(localStorage.getItem('card'));
      var card_count = card.length;
      for (var i = 0; i < card_count; i++) {
        if (card[i].x["Campaign"]) {
          index = 1;
          break;
        }
      }

      if (index == 1) {

        return true;
      }
      else {
        return false;
      }



    }

  }


  allow_discount_code() {


    if (this.service_in_campaign() == false && this.time_in_discount() == false) {
      return true;
    }
    else {
      return false;
    }

  }


  get_previous_url() {
    var url = localStorage.getItem('previous_url');
    var url_split = url.split('/');
    return url.split('/')[url_split.length - 1];
  }


  scroll_end() {
    if ((window.innerHeight + window.scrollY) >= document.body.scrollHeight) {
      return true;
    }
    else {
      return false;
    }
  }

  share(type, title) {
    $("#share").jsSocials({
      url: " ",
      showLabel: false,
      text: " \n " + this.translate.data.share_text_part_one + " " + type + " " + title + "" + this.translate.data.share_text_part_two + ". https://www.butyle.ir",
      showCount: false,
      shares: ["email", "twitter", "facebook", "googleplus", "linkedin", "pinterest", "stumbleupon", "whatsapp"]
    });
  }

  persion_to_en(str) {

    // convert persian digits [۰۱۲۳۴۵۶۷۸۹]
    var e = '۰'.charCodeAt(0);
    str = str.replace(/[۰-۹]/g, function(t) {
        return t.charCodeAt(0) - e;
    });

    // convert arabic indic digits [٠١٢٣٤٥٦٧٨٩]
    e = '٠'.charCodeAt(0);
    str = str.replace(/[٠-٩]/g, function(t) {
        return t.charCodeAt(0) - e;
    });
    return str;
}

  persion_to_ens(data) {

    var fa_numbers = ['۰', '۱', '۲', '۳', '۴', '۵', '۶', '۷', '۸', '۹', '٩', '٨', '٧', '٦', '٥', '٤', '٣', '٢', '١', '٠'];
    var fa_numbers_length = fa_numbers.length;
    var en_numbers = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];

    var data_length = String(data).split('').length;
    var en_number = "";

    var exist = 0;

    console.log(data_length)

    for (var i = 0; i < data_length; i++) {


      for (var n = 0; n < fa_numbers_length; n++) {

        if (data.split('')[i] == fa_numbers[n]) {

          exist = 1;

          en_number += String(en_numbers[n]);


        }
      }

      if (exist == 0) {
        en_number += data.split('')[i];
      }





    }

    if (en_number.length == 0) {

      en_number = data;

    }

    return en_number;


  }




}
