import { NgModule,APP_INITIALIZER ,ModuleWithProviders  } from '@angular/core';
import { CommonModule } from '@angular/common';

import {TranslateService}  from './services/translate.service';
import { TranslatePipe } from './../share/pipes/translate.pipe';
import {MessageService} from './services/message.service';

export function setupTranslateFactory(service:TranslateService):Function{
  localStorage.setItem('language','fa');
  return ()=>service.use('fa');
}
 
import { ToastrModule } from 'ngx-toastr';
import { SideMenuComponent } from './components/side-menu/side-menu.component';
import {User} from './models/db_models/user';

import { HeaderComponent } from './components/header/header.component';
import { ConvertToPixelPipe } from './pipes/convert-to-pixel.pipe';
import { LoaderComponent } from './components/loader/loader.component'; 
import {FormsModule,ReactiveFormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
import { TranslateApiPipe } from './pipes/translate-api.pipe';
import { SearchComponent } from './components/search/search.component';
import { ShareAppComponent } from './share-app/share-app.component';
import {Auth} from './commonClass/auth';
import { WeekDaysPipe } from './pipes/week-days.pipe';
import { TimeConvertPipe } from './pipes/global.pipe';
import { DateConvertPipe } from './pipes/date-convert.pipe';
import {IsLikePipe} from './pipes/global.pipe';
import { CommentComponent } from './components/comment/comment.component';
import { AddCommentComponent } from './components/add-comment/add-comment.component';
import { BackHeaderComponent } from './components/back-header/back-header.component';
import {RouterModule} from '@angular/router';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { CampaignPipe } from './pipes/campaign.pipe';
import {ActivaClassPipe} from './pipes/global.pipe';
import { PaymentTypePipe } from './pipes/payment-type.pipe';
import { SocialShareComponent } from './components/social-share/social-share.component';
import { MessageStatusPipe } from './pipes/message-status.pipe';
import { CreditPipe } from './pipes/credit.pipe';
import { UserBalancePipe } from './pipes/user-balance.pipe';
import { StarPipe } from './pipes/star.pipe';
import { CampaignPagePipe } from './pipes/campaign-page.pipe';
@NgModule({
  declarations: [
    SideMenuComponent,
    TranslatePipe,
    HeaderComponent,
    ConvertToPixelPipe,
    LoaderComponent,
    TranslateApiPipe,
    SearchComponent,
    ShareAppComponent,
    WeekDaysPipe,
    TimeConvertPipe,
    DateConvertPipe,
    IsLikePipe,
    CommentComponent,
    AddCommentComponent,
    BackHeaderComponent,
    CampaignPipe,
    ActivaClassPipe,
    PaymentTypePipe,
    SocialShareComponent,
    MessageStatusPipe,
    CreditPipe,
    UserBalancePipe,
    StarPipe,
    CampaignPagePipe

  ],
  imports: [
    CommonModule,
    ToastrModule.forRoot({
      easeTime:0
    }), // ToastrModule added
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    InfiniteScrollModule

  ],
  providers: [
    {
      provide:APP_INITIALIZER,
      useFactory:setupTranslateFactory,
      deps:[TranslateService],
      multi:true
    },
    User,
    Auth
  ],
  exports:[
    SideMenuComponent,
    TranslatePipe,
    HeaderComponent,
    ConvertToPixelPipe,
    LoaderComponent,
    TranslateApiPipe,
    WeekDaysPipe,
    TimeConvertPipe,
    DateConvertPipe,
    FormsModule,
    ReactiveFormsModule,
    IsLikePipe,
    CommentComponent,
    AddCommentComponent,
    BackHeaderComponent,
    InfiniteScrollModule,
    CampaignPipe,
    ActivaClassPipe,
    SocialShareComponent,
    MessageStatusPipe,
    CreditPipe,
    UserBalancePipe,
    StarPipe,
    CampaignPagePipe

    //message

  ]
})
export class ShareModule {
 


 }
