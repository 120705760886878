import {  PipeTransform, Pipe } from '@angular/core';
import { CommonService } from './../services/common.service';

@Pipe({
  name: 'timeConvert'
})
export class TimeConvertPipe implements PipeTransform {

  transform(value: any): any {

    var final_hour;
    var final_minute;
    var minute = value % 60;
    var hour = Math.floor(Number(value) / 60);
    if (hour < 10) {
      final_hour = "0" + hour;
    }
    else {
      final_hour = hour;
    }
    if (minute < 10) {
      final_minute = "0" + minute;
    }
    else {
      final_minute = minute
    }
    var data = <any>{};
    data.minute = minute;
    data.hour = hour;
    return " " + final_hour + ":" + final_minute + "  ";
   // return value;

  }

}

@Pipe({
  name: 'isLike'
})
export class IsLikePipe implements PipeTransform {

  constructor(private common: CommonService) {

  }
  transform(value: any): any {

    var index = this.common.find_like(value);
    console.log(index + " " + value)
    if (index > -1) {
      return "fa-heart gold";
    }
    else {
      return "fa-heart-o black";
    }


  }
}
@Pipe({ name: "activeClass" })
export class ActivaClassPipe implements PipeTransform {
 
  transform(item: any): any {
      if(item.active==true){
        return 'active';
      }
      else{
        return '';
      }
       

  }
}
